<template>
  <div>
    <div class="table_content">
      <!-- 表格内容 -->
      <commonTitle></commonTitle>

      <div class="search">
        <el-form ref="form" :model="form" label-width="80px" :inline="true">
          <el-form-item label="小区名称:">
            <el-input v-model="form.name" placeholder="请输入小区名称" size="mini"></el-input>
          </el-form-item>
          <template>
            <button @click.prevent="common()" style="cursor: pointer;">搜索</button>
          </template>
        </el-form>
      </div>
      <!--表格-->
      <template>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          @selection-change="handleSelectionChange"
          v-loading="loading"
          element-loading-text="拼命加载中"
        >
          <el-table-column label="小区名称" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-link
                type="primary"
                :underline="false"
                @click="lookTower(scope.row)"
              >{{scope.row.villageName}}</el-link>
            </template>
          </el-table-column>
          <el-table-column property="coveredarea" label="建筑面积" show-overflow-tooltip></el-table-column>
          <el-table-column property="coveredarea" label="占地面积" show-overflow-tooltip></el-table-column>
          <el-table-column
            property="settingTime"
            label="建立时间"
            show-overflow-tooltip
            :formatter="formatSettingTime"
          ></el-table-column>
          <el-table-column
            property="actResult"
            label="审批结果"
            show-overflow-tooltip
            :formatter="formatActResult"
          ></el-table-column>
        </el-table>
        <el-pagination
          background
          style="margin-top: 50px"
          layout="prev, pager, next"
          :page-size="15"
          @current-change="handleCurrentChange"
          :total="totalNum"
        ></el-pagination>
      </template>
      <!--分页-->
    </div>
  </div>
</template>

<script>
import qs from "qs";
import commonTitle from "../table/commonTitle";

export default {
  name: "proVillage",
  components: {
    commonTitle
  },
  data() {
    return {
      url: this.$Config.base_server,
      form: {
        name: "",
        region: ""
      },
      tableData: [],
      multipleSelection: [],
      totalNum: 15,
      loading: true,
      data: {
        access_token: localStorage.getItem("token"),
        limit: 15
      }
    };
  },
  mounted() {
    this.common();
  },
  methods: {
    common(currentPage = 0) {
      this.loading = true;
      const that = this;
      that.data.page = currentPage;
      that.data.villageName = that.form.name; //搜索框的值
      $.ajax({
        url: this.url + "/api-public/opevillage/list",
        data: that.data,
        contentType: "application/json",
        type: "get",
        success: function(res) {
          if (res.code == 0) {
            that.totalNum = res.count;
            that.tableData = res.data;
          }
          that.loading = false;
        },
        error: function(res) {
          that.loading = false;
        }
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleCurrentChange(val) {
      //当前页数改变
      this.common(val);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    formatActResult(row, column) {
      //控制审批状态
      if (row.status == "0") {
        return "未提交";
      } else if (row.status == "1") {
        return "审批通过";
      } else if (row.status == "2") {
        return "审批拒绝";
      } else if (row.status == "3") {
        return "审批中";
      } else if (row.status == "" || row.status == null) {
        return "审批通过";
      }
    },
    formatSettingTime(row) {
      //格式化时间
      let date = new Date(row.settingTime);
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return date.getFullYear() + "-" + month + "-" + day;
    },
    formatSubButtom(row) {
      console.log(row.actResult);
      if (row.actResult == 4) {
        //草稿状态显示提交审核按钮
      }
    },
    lookTower(row) {
      //查看楼幢
      //存储小区数据
      localStorage.setItem("villageData", JSON.stringify(row));
      this.$router.push({ name: "proTower" });
    }
  }
};
</script>

<style scoped lang="scss">
.el-button {
  padding: 5px 5px;
}
</style>